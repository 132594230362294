import { combineReducers, compose } from "redux";
import { nodes } from "./nodes/reducer"
import { pointer } from "./pointer/reducer";
import { editor } from "./editor/reducer";
import { focus } from "./focus/reducer";
import { breadcrumb } from "./breadcrumb/reducer";
import undoable from "./undoable/reducer";

export const history= undoable(combineReducers({nodes}));

export default combineReducers({pointer, history, editor, focus, breadcrumb} as any);
