import React, { Dispatch } from "react";
import { connect, MapStateToProps, MapDispatchToProps } from "react-redux";
import {Breadcrumb} from "../components/Breadcrumb";
import { IInitialHistoryState, IInitialState, IInitialStateWithHistory } from "../redux/nodes/reducer";
import { INode } from "../redux/nodes/actions";
import { updateCurrentBreadcrumb } from "../redux/breadcrumb/actions";
import { updatePointer } from "../redux/pointer/actions";

export interface IBreadcrumbMapState {
  currentNode: INode;
  nodeAncestors: INode[]; // Array of node ids. Sorted by eldest first.
}

export interface IBreadcrumbDispatchState { 
  updateCurrentBreadcrumb: (payload: INode) => void;
}

export type IBreadcrumbState = {currentNode: INode}

const mapStateToProps = (state: IInitialStateWithHistory): IBreadcrumbMapState => {
  // Derive ancestry from current node.
  let ancestry = []
  let currentNode = state.breadcrumb.currentNode;

  while (currentNode.parent !== "ROOTX") {
    ancestry.unshift(state.history.present.nodes[currentNode.parent])
    currentNode = state.history.present.nodes[currentNode.parent]
  }

  return {
    currentNode: state.breadcrumb.currentNode,
    nodeAncestors: ancestry,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): IBreadcrumbDispatchState => {
  return {
    updateCurrentBreadcrumb: (node: INode) => {
      // TODO: dispatch pointer update.
      dispatch(updateCurrentBreadcrumb(node));
      dispatch(updatePointer({id: node.id, childIndex: node.children.length}));
    }
  };
};

const connectedNodes = connect(
  mapStateToProps,
  mapDispatchToProps
)(Breadcrumb);

export default connectedNodes as any;
