import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { createStore, Reducer, Action } from "redux";
import { devToolsEnhancer } from "redux-devtools-extension";
import {App} from "./App";
import reducer from "./redux/rootReducer";
import * as serviceWorker from "./serviceWorker";
import { IInitialStateWithHistory } from "./redux/nodes/reducer";
import "./index.scss";
import { loadState, saveState } from "./localStorage";

const persistedState: IInitialStateWithHistory | undefined = loadState();
const store = createStore(
  reducer,
  persistedState,
  devToolsEnhancer({})
);

store.subscribe(() => {
  saveState(store.getState());
});

render(
  <Provider store={store}>
    <App/>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
