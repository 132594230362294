export interface IEditor {
  body: string;
}
export interface IEditorAction {
  type: "UPDATE_EDITOR";
  payload: IEditor;
}

/*
 * action types
 */

export const EditorActions = {
  UPDATE_EDITOR: "UPDATE_EDITOR"
};

/*
 * action creators
 */

export function updateEditor(payload: IEditor) {
  return { type: EditorActions.UPDATE_EDITOR, payload};
}
