import { EUndoAction } from "./actions";
import { initialState } from "../nodes/reducer";

//export interface 

export default function undoable(reducer: any) {
  // Call the reducer with empty action to populate the initial state
  const initialUndoableState = {
    past: [],
    present: reducer(),
    future: []
  }

  // Return a reducer that handles undo and redo
  return function(state = initialUndoableState, action: any) {
    const { past, present, future } = state

    switch (action.type) {
      case EUndoAction.UNDO:
        const previous = past[past.length - 1]
        const newPast = past.slice(0, past.length - 1)
        return {
          past: newPast,
          present: previous,
          future: [present, ...future]
        }
      case EUndoAction.REDO:
        const next = future[0]
        const newFuture = future.slice(1)
        return {
          past: [...past, present],
          present: next,
          future: newFuture
        }
      default:
        // Delegate handling the action to the passed reducer
        const newPresent = reducer(present, action)
        if (present === newPresent) {
          return state
        }
        return {
          past: [...past, present],
          present: newPresent,
          future: []
        }
    }
  }
}