import produce from "immer";
import {
  INode,
  NodeActions,
  IChildPayload,
  INodeMovePayload,
  INodesUpdatePayload,
  INodeDragDropPayload
} from "./actions";
import { IPointer } from "../pointer/actions";
import { IEditor } from "../editor/actions";
import { IFocusEntity } from "../focus/actions";
import Breadcrumb, { IBreadcrumbState } from "../../containers/Breadcrumb";
import { resumeState, tutorialState} from "../tutorialState";

const {
  CREATE_NODE,
  UPDATE_NODE,
  DELETE_NODE,
  MOVE_NODE,
  SHOW_CHILDREN
} = NodeActions;

export interface INodes {
  [k: string]: INode;
}

export interface IInitialState {
  nodes: INodes;
  pointer: IPointer;
  editor: IEditor;
  focus: IFocusEntity;
  breadcrumb: IBreadcrumbState;
}

export interface IInitialHistoryState {
  nodes: INodes;
}

export interface IInitialStateWithHistory {
  [x: string]: {};
  history: {
    past: IInitialHistoryState[];
    present: IInitialHistoryState;
    future: IInitialHistoryState[];
  };
  editor: IEditor;
  pointer: IPointer;
  focus: IFocusEntity;
  breadcrumb: IBreadcrumbState;
}

export interface INodeActions {
  [k: string]: INodeAction;
}

export interface INodeAction {
  type: string;
  payload: INode;
}

export interface INodeChildAction {
  type: string;
  payload: IChildPayload;
}

export interface INodeMoveAction {
  type: string;
  payload: INodeMovePayload;
}

export interface INodeDragDropAction {
  type: string;
  payload: INodeDragDropPayload;
}

export interface INodesUpdateAction {
  type: string;
  payload: INodesUpdatePayload;
}

export interface INodeShowChildrenAction {
  type: string;
  payload: INode;
}

function isNodeCreateOrDeleteAction(
  action: INodeAction | INodeChildAction | INodeMoveAction | INodeDragDropAction
): action is INodeAction {
  return (
    (<INodeAction>action).payload.id !== undefined &&
    (<INodeAction>action).payload.parent !== undefined
  );
}

function isNodeMoveAction(
  action: INodeAction | INodeChildAction | INodeMoveAction | INodeDragDropAction
): action is INodeMoveAction {
  return (
    (<INodeMoveAction>action).payload.from !== undefined &&
    (<INodeMoveAction>action).payload.to !== undefined
  );
}

function isNodeDragDropAction(action: any): action is INodeDragDropAction {
  return (
    (<INodeDragDropAction>action).payload.updatedNode !== undefined &&
    (<INodeDragDropAction>action).payload.prevParent !== undefined &&
    (<INodeDragDropAction>action).payload.nextParent !== undefined &&
    (<INodeDragDropAction>action).payload.prevChildIndex !== undefined &&
    (<INodeDragDropAction>action).payload.nextChildIndex !== undefined
  );
}

function isNodeShowChildrenAction(
  action: any
): action is INodeShowChildrenAction {
  return (
    (<INodeShowChildrenAction>action).payload.id !== undefined &&
    (<INodeShowChildrenAction>action).payload.parent !== undefined &&
    (<INodeShowChildrenAction>action).payload.children !== undefined &&
    (<INodeShowChildrenAction>action).payload.data !== undefined &&
    (<INodeShowChildrenAction>action).payload.type !== undefined &&
    (<INodeShowChildrenAction>action).payload.childIndex !== undefined
  );
}

export const rootNode: INode = {
  id: "ROOT",
  data: { text: "ListFold" },
  parent: "ROOTX", // Note: This sets up a circular relationship.
  children: [],
  childIndex: 0,
  type: "textarea",
  complete: false,
  showChildren: true,
};

  // If tutorial is the subdomain, show the tutorial state...
  let nodeState: INodes = {ROOT: rootNode}
  if ( window.location.host.split('.')[0] === 'tutorial' ) {
    nodeState = tutorialState.nodes;
  }
  if ( window.location.host.split('.')[0] === 'resume' ) {
    nodeState = resumeState.nodes;
  }

export const initialState: IInitialState = {
  nodes: nodeState,
  pointer: {id: nodeState.ROOT.id, childIndex: 0},
  focus: { id: nodeState.ROOT.id },
  editor: { body: "" },
  breadcrumb: {
    currentNode: nodeState.ROOT
  }
};

const getAllDescendantIds = (state: INodes, nodeId: string) => {
  const reducer = (acc: Array<string>, curr: string): Array<string> => {
    return [...acc, curr, ...getAllDescendantIds(state, curr)];
  };
  const ids = state[nodeId].children.reduce(reducer, []);
  return ids;
};

// Given an array of ids (childIds), ensure each node with that id has a childIndex property that correlates to its index in the childIds array.
const updateNodesChildIndex = (arr: string[], nodes: INodes): INodes => {
  arr.map((id, ix) => (nodes[id].childIndex = ix));
  return nodes;
};

export const nodes = (
  state = initialState.nodes,
  action: INodeAction | INodeChildAction | INodeMoveAction | INodeDragDropAction
) => {
  if (typeof action === "undefined") {
    return state;
  }
  if (typeof action.payload === "undefined") {
    return state;
  }
  if (isNodeCreateOrDeleteAction(action)) {
    switch (action.type) {
      case CREATE_NODE:
        return produce(state, draftState => {
          draftState[action.payload.parent].children.splice(
            action.payload.childIndex,
            0,
            action.payload.id
          );
          draftState[action.payload.id] = action.payload;
          draftState[action.payload.parent].children.map(
            (id: string, i: number) => {
              draftState[id].childIndex = i;
            }
          );
        });
      case DELETE_NODE:
        return produce(state, draftState => {
          // Delete the children.
          if (action.payload.children.length > 0) {
            getAllDescendantIds(
              draftState as INodes,
              action.payload.parent
            ).map((id: string, ix: number) => {
              delete draftState[id];
            });
          }
          // Delete the node.
          delete draftState[action.payload.id];
          // Delete the node from the parent.
          draftState[action.payload.parent].children = draftState[
            action.payload.parent
          ].children.filter((id: string) => id !== action.payload.id);
          // Update remaining siblings.
          draftState[action.payload.parent].children.map(
            (id: string, i: number) => {
              draftState[id].childIndex = i;
            }
          );
        });
      case UPDATE_NODE:
        return produce(state, draftState => {
          if (draftState[action.payload.id] !== undefined) {
            draftState[action.payload.id] = action.payload;
          }
        });
    }
  }

  if (isNodeDragDropAction(action)) {
    // Node is the node being dragged.
    // Previous Parent is the last assigned parent of the node.
    // Parent is the parent of the node being hovered over.
    // childIndex is the index of the node being hovered over + 1.
    const {
      updatedNode,
      prevParent,
      prevChildIndex,
      nextParent,
      nextChildIndex
    } = action.payload;
    switch (action.type) {
    }
  }

  if (isNodeMoveAction(action)) {
    switch (action.type) {
      case MOVE_NODE:
        return produce(state, draftState => {
          const { node, from, to, index } = action.payload;

          if (from.id === to.id) {
            draftState[to.id].children = draftState[to.id].children.filter(
              (id: string) => {
                return id !== node.id;
              }
            );
            draftState[to.id].children.splice(index, 0, node.id);
            draftState[to.id].children.map((id: string, ix: number) => {
              draftState[id].childIndex = ix;
            });
          }

          if (from.id !== to.id) {
            draftState[from.id].children = draftState[from.id].children.filter(
              (id: string) => {
                return id !== node.id;
              }
            );
            draftState[from.id].children.map((id: string, ix: number) => {
              draftState[id].childIndex = ix;
            });
            draftState[to.id].children.splice(index, 0, node.id);
            draftState[to.id].children.map((id: string, ix: number) => {
              draftState[id].childIndex = ix;
            });
            draftState[node.id].parent = to.id;
          }
        });

      default:
        return state;
    }
  }
  if (isNodeShowChildrenAction(action)) {
    switch (action.type) {
      case SHOW_CHILDREN:
        return produce(state, draftState => {
          const { id } = action.payload;
          draftState[id].showChildren = action.payload.showChildren;
        });
    }
  }
  return state;
};
