export interface IFocusEntity {
  id: string;
}
export interface ISortAction {
  type: "MAINTAIN_FOCUS";
  payload: IFocusEntity;
}

/*
 * action types
 */

export const SortActions = {
  MAINTAIN_FOCUS: "MAINTAIN_FOCUS"
};

const {MAINTAIN_FOCUS} = SortActions

/*
 * action creators
 */

export function currentFocus(payload: IFocusEntity) {
  return { type: MAINTAIN_FOCUS, payload};
}
