import React, { useState, useRef, useImperativeHandle } from "react";
import { ConnectDragSource, ConnectDropTarget } from "react-dnd";
import "./Node.scss";
import { Editor } from "./Editor";
import { IPointer } from "../redux/pointer/actions";
import { INode, INodeMovePayload, showChildren } from "../redux/nodes/actions";
import { IEditor } from "../redux/editor/actions";
import { INodes } from "../redux/nodes/reducer";
import { IFocusEntity } from "../redux/focus/actions";
import { ReactComponent as DownIcon } from "../svg/ant_down.svg";
import { ReactComponent as RightIcon } from "../svg/ant_right.svg";
import { ReactComponent as LeftVerticle } from "../svg/ant_verticle_left.svg";
import { ReactComponent as RightVerticle } from "../svg/ant_verticle_right.svg";

import { NodeControlBullet } from "../components/NodeControlBullet";

import { IBreadcrumbState } from "../containers/Breadcrumb";

export interface INodeDragDropProps {
  connectDragSource: ConnectDragSource;
  connectDropTarget: ConnectDropTarget;
  isDragging: boolean;
  isOver: boolean;
  isOverCurrent: boolean;
  canDrop: boolean;
}

// TODO: This interface should be derived from mapStateToProps and mapDispatchToProps return types and not redefined here.
export interface INodeProps {
  node: INode;
  nodes: INodes;
  pointer: IPointer;
  editor: IEditor;
  currentFocus: IFocusEntity;
  breadcrumb: IBreadcrumbState;
  updatePointer: (arg0: IPointer) => void;
  updateEditor: (arg0: IEditor) => void;
  createNode: (arg0: INode) => void;
  updateNode: (arg0: INode) => void;
  deleteNode: (arg0: INode) => void;
  moveNode: (arg0: INodeMovePayload) => void;
  updateFocus: (arg0: IFocusEntity) => void;
  updateCurrentBreadcrumb: (arg0: INode) => void;
  toggleChildren: (arg0: INode) => void;
}

export interface INodeInstance {
  getNode(): HTMLDivElement | null;
}

export const Node: React.RefForwardingComponent<
  HTMLDivElement,
  INodeProps
> = React.forwardRef(
  (
    {
      node,
      nodes,
      pointer,
      updatePointer,
      editor,
      updateEditor,
      updateNode,
      createNode,
      deleteNode,
      moveNode,
      currentFocus,
      updateFocus,
      updateCurrentBreadcrumb,
      breadcrumb,
      toggleChildren
    }: INodeProps,
    ref
  ) => {
    const elementRef = useRef(null);

    const tabNode = (node: INode, nodes: INodes) => {
      const sibling = nodes[nodes[node.parent].children[node.childIndex - 1]];
      if (sibling !== undefined) {
        moveNode({
          from: nodes[node.parent],
          to: sibling,
          node: nodes[node.id],
          index: sibling.children.length
        });
        // Show children of new parent.
        toggleChildren({ ...sibling, showChildren: true });
      }
    };

    const shiftTabNode = (node: INode, nodes: INodes) => {
      const toNode = nodes[nodes[node.parent].parent];
          const fromNode = nodes[node.parent];
          moveNode({
            from: fromNode,
            to: toNode,
            node: nodes[node.id],
            index: fromNode.childIndex + 1
          });
          // TODO: Should also update the breadcrumb trail if the node being moved is the current breadcrumb.
    }

    const children = node.children.map((n: string) => {
      const nx = nodes[n];
      if (nx === undefined) {
        return;
      }
      return (
        <div
          key={`node--child--${nx.id}--${nx.parent}--${nx.childIndex}`}
          className={`node--child node--child--alignmentinidcator`}
        >
          {
            <Node
              node={nx}
              nodes={nodes}
              pointer={pointer}
              updatePointer={updatePointer}
              editor={editor}
              updateEditor={updateEditor}
              updateNode={updateNode}
              deleteNode={deleteNode}
              createNode={createNode}
              moveNode={moveNode}
              currentFocus={currentFocus}
              updateFocus={updateFocus}
              updateCurrentBreadcrumb={updateCurrentBreadcrumb}
              breadcrumb={breadcrumb}
              toggleChildren={toggleChildren}
            />
          }
        </div>
      );
    });

    // If the pointer has a child index. splice the array of children.
    const childrenTop = children.slice(0, pointer.childIndex);
    const childrenBottom = children.slice(pointer.childIndex, children.length);

    const handleKeyPress = (node: INode) => (
      e: React.KeyboardEvent<HTMLTextAreaElement>
    ) => {
      // Delete Node.
      if (
        e.key === "Backspace" &&
        node.data.text === "" &&
        node.children.length === 0
      ) {
        e.preventDefault();
        // If we are deleteing the first and only child of the root node, then there are 2 nodes and Ensure the pointer remains visible.
        if (Object.keys(nodes).length === 2) {
          updatePointer({ id: "ROOT", childIndex: 0 });
        }
        deleteNode(node);
      }
      // Update Node.
      if (e.key === "Enter" && e.shiftKey === false) {
        e.preventDefault();
        // If we have selectionStart / selectionEnd with the user's browser we can split.
        if ("selectionStart" in e.target && "selectionEnd" in e.target) {
          let enhancedBrowserField: HTMLTextAreaElement = e.target;
          splitter(
            enhancedBrowserField.selectionStart,
            enhancedBrowserField.selectionEnd,
            node
          );
        } else {
          // If it's not easy to find the cursor position in the input field - just put the pointer below.
          updatePointer({ id: node.parent, childIndex: node.childIndex + 1 });
        }
      }
      // Move Node Right.
      // If tab is pressed, and there's a child available, move the node.
      if (e.key === "Tab" && e.shiftKey === false) {
        e.preventDefault();
        tabNode(node, nodes);
      }
      // Move Node Left.
      // If shift tab is pressed, move the node back.
      if (e.key === "Tab" && e.shiftKey === true) {
        e.preventDefault();
        if (node.parent !== "ROOT") {
          shiftTabNode(node, nodes)
        }
      }
    };

    const updateDataText = (node: INode, b: string): INode => {
      return { ...node, data: { text: b } };
    };

    const handleChange = (node: INode) => (
      e: React.ChangeEvent<HTMLTextAreaElement>
    ): void => {
      e.preventDefault();
      updateNode(updateDataText(node, e.target.value));
    };

    // TODO: Consider putting this in the pointer reducer, note it affects the node body also...
    // Updates the pointer according to what node was pressed and where in the node it was pressed.
    const splitter = (
      selectionStart: number,
      selectionEnd: number,
      i: INode
    ) => {
      let childIndex = node.childIndex;
      let pointerId = node.parent;
      if (
        // if cursor is at beginning, put pointer over:
        selectionStart === 0 &&
        selectionEnd === 0
      ) {
      } else if (
        // if cursor is in middle of the node body, split RHS onto new input below the current node.
        selectionStart !== 0
      ) {
        const lhs = node.data.text.slice(0, selectionStart);
        let rhs = node.data.text.slice(
          selectionStart,
          node.data.text.split("").length
        );
        // if first character in RHS is a  newline character strip it.
        if (rhs.indexOf("\n") === 0) {
          rhs = rhs.substring(1);
        }
        // having split the content of the node, find the new number of rows that it should have.
        childIndex += 1;
        updateNode(updateDataText(i, lhs));
        updateEditor({ body: rhs });
      } else if (
        // Default behaviour is to put pointer under:
        selectionEnd === node.data.text.split("").length &&
        selectionStart === node.data.text.split("").length
      ) {
        childIndex += 1;
      }
      // Perform pointer update.
      updatePointer({ id: pointerId, childIndex: childIndex });
    };

    // TODO: Doublecheck - but should respond to pointer events, not mouse events.
    const handleBlur = (node: INode) => (
      e: React.FocusEvent<HTMLTextAreaElement>
    ) => {
      if (currentFocus.id === node.id) {
        updateFocus({ id: "null" });
      }
    };

    // TODO: Doublecheck - but should respond to pointer events, not mouse events.
    const handleFocus = (node: INode) => (
      e: React.FocusEvent<HTMLTextAreaElement>
    ) => {
      updateFocus({ id: node.id });
    };

    // TODO: Doublecheck - but should respond to pointer events, not mouse events.
    const handleExpand = (node: INode) => {
      updateCurrentBreadcrumb(node);
      toggleChildren({ ...node, showChildren: true });
      // TODO: Set pointer to be first child of current breadcrumb...
    };
    const handleShowChildren = (node: INode) => {
      toggleChildren({ ...node, showChildren: !node.showChildren });
    };
    return (
      <div
        className={`node--dnd--ref node--layout node--layout--horizontalX`}
        ref={elementRef}
      >
        {node.id !== breadcrumb.currentNode.id && (
          <div className={`node--container`}>
            <div
              className={`node node--hover--false node--isDragging--false`}
            >
              {node.children.length > 0 && node.showChildren && (
                <div
                  className={`node--show--children`}
                  onClick={() => handleShowChildren(node)}
                >
                  <DownIcon />
                </div>
              )}
              {node.children.length > 0 && !node.showChildren && (
                <div
                  className={`node--show--children`}
                  onClick={() => handleShowChildren(node)}
                >
                  <RightIcon />
                </div>
              )}
              <div
                className={`node--control node--control--isDragging--false`}
                onClick={() => handleExpand(node)}
              >
                <NodeControlBullet />
              </div>
              <div className={`node--body`}>
                <textarea
                  className={`node--input`}
                  onChange={handleChange(node)}
                  value={node.data.text}
                  onKeyDown={handleKeyPress(node)}
                  cols={node.data.text
                    .split("\n")
                    .reduce((acc: number, curr: string) => {
                      if (curr.length > acc) {
                        return curr.length;
                      } else {
                        return acc;
                      }
                    }, 0)}
                  rows={node.data.text.split("\n").length}
                  autoFocus={currentFocus.id === node.id}
                  onBlur={handleBlur(node)}
                  onFocus={handleFocus(node)}
                />
              </div>
              {node.parent !== breadcrumb.currentNode.id && (
                <div className={`node--rightverticle`} onClick={()=>shiftTabNode(node, nodes)}>
                  <RightVerticle />
                </div>
              )}
              {node.childIndex !== 0 && (
                <div className={`node--leftverticle`} onClick={() => tabNode(node, nodes)}>
                  <LeftVerticle />
                </div>
              )}
            </div>
          </div>
        )}
        <div className={`node--children`}>
          {node.showChildren && childrenTop}
          {node.id === pointer.id && (
            <div className={`node--editor`}>
              <Editor
                nodes={nodes}
                pointer={pointer}
                updatePointer={updatePointer}
                editor={editor}
                updateEditor={updateEditor}
                node={node}
                createNode={createNode}
                toggleChildren={toggleChildren}
                breadcrumb={breadcrumb}
              />
            </div>
          )}
          {node.showChildren && childrenBottom}
        </div>
      </div>
    );
  }
);
