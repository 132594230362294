export interface IPointer {
  id: string;
  childIndex: number;
}
export interface IPointerAction {
  type: "UPDATE_POINTER";
  payload: IPointer;
}

/*
 * action types
 */

export const PointerActions = {
  UPDATE_POINTER: "UPDATE_POINTER"
};

const {UPDATE_POINTER} = PointerActions

/*
 * action creators
 */

export function updatePointer(payload: IPointer) {
  return { type: UPDATE_POINTER, payload};
}
