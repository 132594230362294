import { INode } from "../nodes/actions";

export interface IBreadcrumbAction {
  type: string;
  payload: INode;
}

/*
 * action types
 */
export const BreadcrumbActions = {
  UPDATE_CURRENT_BREADCRUMB: "UPDATE_CURRENT_BREADCRUMB"
};

/*
 * action creators
 */
export function updateCurrentBreadcrumb(payload: INode) {
  return { type: BreadcrumbActions.UPDATE_CURRENT_BREADCRUMB, payload};
}
