import React, { Dispatch, useEffect, useRef, useState } from "react";
import uuidv4 from "uuid";
import "./Editor.scss";
import { INode } from "../redux/nodes/actions";
import { IEditor } from "../redux/editor/actions";
import { IPointer } from "../redux/pointer/actions";
import { INodes, rootNode } from "../redux/nodes/reducer";
import { IBreadcrumbState } from "../containers/Breadcrumb";

export interface IEditorProps {
  node: INode;
  nodes: INodes;
  editor: IEditor;
  pointer: IPointer;
  updatePointer: (arg0: IPointer) => void;
  updateEditor: (arg0: IEditor) => void;
  createNode: (arg0: INode) => void;
  toggleChildren: (arg0: INode) => void;
  breadcrumb: IBreadcrumbState;
}

export const Editor = ({
  node,
  nodes,
  pointer,
  updatePointer,
  editor,
  updateEditor,
  createNode,
  toggleChildren,
  breadcrumb
}: IEditorProps) => {
  const editorRef: React.MutableRefObject<any> = useRef();
  // Find longest row.
  const findMaxCol = (body: string) => {
    return body.split("\n").reduce((acc: number, curr: string) => {
      if (curr.length > acc) {
        return curr.length;
      } else {
        return acc;
      }
    }, 10);
  };
  const maxRow = editor.body.split("\n").length;
  const maxCol = findMaxCol(editor.body);
  const [rowCount, updateRows] = useState(maxRow);
  const [colCount, updateCols] = useState(
    maxCol === 0 ? "List Fold.".length : maxCol
  );
  useEffect(() => {
    editorRef.current.focus();
  });

  const submitNode = () => {
    const newNode: INode = {
      id: uuidv4(),
      data: { text: editor.body },
      children: [],
      childIndex: pointer.childIndex,
      parent: node.id,
      type: "textarea",
      showChildren: true,
      complete: false
    };
    createNode(newNode);
    updateEditor({ body: "" });
    updateCols(0);
    updatePointer({ id: node.id, childIndex: pointer.childIndex + 1 });
  };

  const handleKeyDown = (node: INode) => (
    e: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    // If return is pressed, submit the node.
    if (e.key === "Enter" && e.shiftKey === false) {
      e.preventDefault();
      submitNode();
      //updateRows(1)
    }
    // If Shift return is pressed, add a row to the text area.
    if (e.key === "Enter" && e.shiftKey === true) {
      //updateRows(rowCount +1)
    }
    // If tab is pressed, update the pointer to the current node.
    if (e.key === "Tab" && e.shiftKey === false && node.children.length > 0) {
      e.preventDefault();
      // If the tab is moving right, from somewhere in the middle of the children. The pointer will point at that child.
      const pointAtId = node.children[pointer.childIndex - 1];
      const pointAtIndex = nodes[pointAtId].children.length;
      // If the tab is moving right, the new parent must have showChildren to be true.
      if (nodes[pointAtId].showChildren === false) {
        toggleChildren({ ...nodes[pointAtId], showChildren: true });
      }
      updatePointer({ id: pointAtId, childIndex: pointAtIndex });
    }
    if (e.key === "Tab" && e.shiftKey === true) {
      e.preventDefault();
      // Don't do anything if the pointer cannot move further left.
      if (node.parent !== breadcrumb.currentNode.parent) {
        updatePointer({ id: node.parent, childIndex: node.childIndex + 1 });
      }
    }
    // Don't update the pointer with the key strokes that come after this - if it would cause the input area to disappear.
    if (rootNode.parent === "ROOTX" && node.childIndex === 0) {
        return;
    }
    if (e.key === "Escape") {
      e.preventDefault();
      updatePointer({ id: node.parent, childIndex: node.childIndex });
    }
    if (editor.body === "") {
      if (e.key === "Backspace" || e.key === "Delete") {
        e.preventDefault();
        updatePointer({ id: node.parent, childIndex: node.childIndex });
      }
    }
  };

  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const maxCol = findMaxCol(e.target.value);
    updateCols(maxCol);
    updateEditor({ body: e.target.value });
  };

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    // If the pointer has text, submit it.
    if (editor.body.length > 0) {
      submitNode();
    }
    // Only hide the open editor if we're not in the root node and its the only node.
    if (node.id !== "ROOT" && Object.keys(nodes).length > 1) {
      updatePointer({ id: "", childIndex: 0 });
    }
    if (node.id === "ROOT" && Object.keys(nodes).length > 1) {
      updatePointer({ id: "", childIndex: 0 });
    }
  };
  return (
    <div className="editor--container">
      <textarea
        id={"editor"}
        className="editor--textarea"
        onKeyDown={handleKeyDown(node)}
        onChange={handleInput}
        value={editor.body}
        ref={editorRef}
        cols={colCount}
        rows={maxRow}
        onBlur={handleBlur}
        placeholder="List Fold"
      />
    </div>
  );
};
