import React from "react";
import "./NodeControlBullet.scss";
import { ReactComponent as Bullet } from "../svg/bullet.svg";
import { ReactComponent as SquareBullet } from "../svg/square.svg";

export const NodeControlBullet: React.FC = () => {
  return (
    <div>
      <Bullet className={"node-control-bullet"} />
    </div>
  );
};
