import React, { useState } from "react";
import { DragDropContextProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import Nodes from "./containers/Nodes";
import Commands from "./containers/Commands";
import { ReactComponent as Filters } from "./svg/filters.svg";
import Breadcrumb from "./containers/Breadcrumb";
import { Controls } from "./components/Controls";

export const App: React.FC = () => {
  const [showControls, toggleControls] = useState(false)
  return (
    <div className="container--page">
      <Filters />
      <Commands />
      <Breadcrumb />
      {/*<Controls />*/}
      <div className="container--list">
        <DragDropContextProvider backend={HTML5Backend}>
          <Nodes />
        </DragDropContextProvider>
      </div>
    </div>
  );
};
