import React, { Dispatch } from "react";
import { connect } from "react-redux";
import { ConnectDragSource } from "react-dnd";
import {Node}
 from "../components/Node";
import {
  INode,
  createNode,
  updateNode,
  deleteNode,
  moveNode,
  INodeMovePayload,
  showChildren
} from "../redux/nodes/actions";
import { redo, undo, IHistoryAction } from "../redux/undoable/actions";
import {
  INodeAction,
  IInitialStateWithHistory,
  INodeMoveAction
} from "../redux/nodes/reducer";
import {
  IPointer,
  updatePointer,
  IPointerAction
} from "../redux/pointer/actions";
import { updateEditor, IEditor, IEditorAction } from "../redux/editor/actions";
import { IFocusEntity, currentFocus } from "../redux/focus/actions";
import { updateCurrentBreadcrumb } from "../redux/breadcrumb/actions";

export interface IAppProps {
  nodes: Array<INode>;
  node: INode;
  createNode: INodeAction;
  updateNode: INodeAction;
  deleteNode: INodeAction;
  moveNode: INodeMoveAction;
  editor: IEditor;
  updateEditor: IEditorAction;
  pointer: IPointer;
  updatePointer: IPointerAction;
  redo: IHistoryAction;
  undo: IHistoryAction;
  depth: Number;
}

export interface SourceBoxProps {
  color?: string;
  forbidDrag?: boolean;
  onToggleForbidDrag?: () => void;

  connectDragSource: ConnectDragSource;
  isDragging: boolean;
}

const mapStateToProps = (state: IInitialStateWithHistory, ownProps: any) => {
  // Nodes is in fact just the children of the current breadcrumb.

  return {
    nodes: state.history.present.nodes,
    node: state.history.present.nodes[state.breadcrumb.currentNode.id],
    pointer: state.pointer,
    editor: state.editor,
    depth: 0,
    currentFocus: state.focus,
    breadcrumb: state.breadcrumb
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    createNode: (node: INode) => dispatch(createNode(node)),
    updateNode: (node: INode) => dispatch(updateNode(node)),
    deleteNode: (node: INode) => dispatch(deleteNode(node)),
    moveNode: (payload: INodeMovePayload) => {
      dispatch(moveNode(payload));
    },
    updatePointer: (pointer: IPointer) => {
      dispatch(updatePointer(pointer));
    },
    updateEditor: (editor: IEditor) => dispatch(updateEditor(editor)),
    toggleChildren: (node: INode) => dispatch(showChildren(node)),
    undo: () => dispatch(undo()),
    redo: () => dispatch(redo()),
    updateFocus: (sortEntity: IFocusEntity) =>
      dispatch(currentFocus(sortEntity)),
    updateCurrentBreadcrumb: (node: INode) => {
      dispatch(updateCurrentBreadcrumb(node));
      dispatch(
        updatePointer({ id: node.id, childIndex: node.children.length })
      );
    }
  };
};

const connectedNodes = connect(
  mapStateToProps,
  mapDispatchToProps
)(Node);

export default connectedNodes as any;
