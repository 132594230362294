import React from "react";
import {
  IBreadcrumbMapState,
  IBreadcrumbDispatchState
} from "../containers/Breadcrumb";
import { INode } from "../redux/nodes/actions";
import "./Breadcrumb.scss";

type IBreadcrumbProps = IBreadcrumbDispatchState & IBreadcrumbMapState;

export const Breadcrumb = ({
  currentNode,
  nodeAncestors,
  updateCurrentBreadcrumb
}: IBreadcrumbProps) => {
  const trail = nodeAncestors.map((node: INode) => {
    const handleClick = (node: INode) => {
      updateCurrentBreadcrumb(node);
    };
    return (
      <div
        key={`breadcrumb--${node.id}`}
        className="breadcrumb--crumb"
        onClick={() => {handleClick(node)}}
      >
       {`${node.data.text}/`} {`    `}
      </div>
    );
  });
  return (
    <span className={"breadcrumb"}>
      {trail}<div className="breadcrumb--active">{currentNode.data.text}</div>
    </span>
  );
};
