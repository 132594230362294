export interface IHistoryAction {
  "type": EUndoAction
}

/*
 * action types
 */

 export enum EUndoAction {
   UNDO = "UNDO",
   REDO = "REDO"
 }

/*
 * action creators
 */

export const undo: ()=>IHistoryAction = (): IHistoryAction => {
  return { type: EUndoAction.UNDO};
}
export function redo() :IHistoryAction {
  return { type: EUndoAction.REDO};
}
