import { v4String } from "uuid/interfaces";
import {
  INodeAction,
  INodeChildAction,
  INodeMoveAction,
  INodes,
  INodesUpdateAction,
  INodeDragDropAction,
  INodeShowChildrenAction
} from "./reducer";

/*
 * Interfaces
 */

export interface INodeData {
  text: string;
  created?: Date;
  author?: string;
  lastEdited?: [string, Date]; // Tuple type where string here is a username.
}

export interface INode {
  type: "textarea" | "heading1" | "heading2" | "heading3" | "quotation" | "divider" | "url" | "code" | "image",
  id: string;
  data: INodeData;
  children: Array<string>;
  childIndex: number;
  parent: string;
  complete: boolean;
  showChildren: boolean;
}

export interface IChildPayload {
  nodeId: string;
  childId: string;
  childIndex: number;
}

export interface INodeMovePayload {
  node: INode;
  from: INode;
  to: INode;
  index: number
}

export interface INodeDragDropPayload {
  prevParent: string,
  nextParent: string,
  prevChildIndex: number,
  nextChildIndex: number,
  updatedNode: INode
}

export interface IDragNodePayload {
  node: INode,
}

export interface INodesUpdatePayload {
  childIds: string[];
}

export type INodeShowChildrenPayload  = INode;

/*
 * action types
 */

export const NodeActions = {
  CREATE_NODE: "CREATE_NODE",
  UPDATE_NODE: "UPDATE_NODE",
  DELETE_NODE: "DELETE_NODE",
  MOVE_NODE: "MOVE_NODE",
  SHOW_CHILDREN: "SHOW_CHILDREN",
};

const {
  CREATE_NODE,
  UPDATE_NODE,
  DELETE_NODE,
  MOVE_NODE,
  SHOW_CHILDREN,
} = NodeActions;
/*
 * action creators
 */

export function createNode(payload: INode) {
  return { type: CREATE_NODE, payload };
}

export function updateNode(payload: INode) {
  return { type: UPDATE_NODE, payload };
}

export function deleteNode(payload: INode) {
  return { type: DELETE_NODE, payload };
}

export function moveNode(payload: INodeMovePayload): INodeMoveAction {
  return { type: MOVE_NODE, payload };
}

export function showChildren(payload: INode) {
  return {type: SHOW_CHILDREN, payload}
} 
/*
export function dragNode (payload: INodeDragDropPayload): INodeDragDropAction {
  return { type: DRAG_NODE, payload}
}
*/
