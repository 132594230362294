import { INodes } from "./nodes/reducer";
import { INode } from "./nodes/actions";

export const tutorialState: {nodes: INodes} = {
      nodes: {
        ROOT: {
          id: 'ROOT',
          data: {
            text: 'ListFold'
          },
          parent: 'ROOTX',
          children: [
            '28a24c04-1823-4386-82e6-560d989c5c8f'
          ],
          childIndex: 0,
          type: 'textarea',
          complete: false,
          showChildren: true
        },
        '28a24c04-1823-4386-82e6-560d989c5c8f': {
          id: '28a24c04-1823-4386-82e6-560d989c5c8f',
          data: {
            text: 'Controls.'
          },
          children: [
            '7de8e1e7-9a6a-4dd5-ab50-9e661f0e671c',
            '96df7b96-fffa-4ee1-bafb-10972e28c100',
            'd88dc9e5-c01b-4b9f-aa4f-8698e1f90093',
            '2787d53c-500c-4aae-bfb1-21370a8ef8c5',
            '89239ff0-a8ef-4e70-bfab-c7c49d55d23a',
            '68823532-1233-46a1-8abc-63d80de3eb1b'
          ],
          childIndex: 0,
          parent: 'ROOT',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        '7de8e1e7-9a6a-4dd5-ab50-9e661f0e671c': {
          id: '7de8e1e7-9a6a-4dd5-ab50-9e661f0e671c',
          data: {
            text: 'tab - indent bullet'
          },
          children: [],
          childIndex: 0,
          parent: '28a24c04-1823-4386-82e6-560d989c5c8f',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        '96df7b96-fffa-4ee1-bafb-10972e28c100': {
          id: '96df7b96-fffa-4ee1-bafb-10972e28c100',
          data: {
            text: 'shift + tab - outdent bullet'
          },
          children: [],
          childIndex: 1,
          parent: '28a24c04-1823-4386-82e6-560d989c5c8f',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        'd88dc9e5-c01b-4b9f-aa4f-8698e1f90093': {
          id: 'd88dc9e5-c01b-4b9f-aa4f-8698e1f90093',
          data: {
            text: 'shift + return - line break within bullet'
          },
          children: [],
          childIndex: 2,
          parent: '28a24c04-1823-4386-82e6-560d989c5c8f',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        '2787d53c-500c-4aae-bfb1-21370a8ef8c5': {
          id: '2787d53c-500c-4aae-bfb1-21370a8ef8c5',
          data: {
            text: 'cmd + z - undo'
          },
          children: [],
          childIndex: 3,
          parent: '28a24c04-1823-4386-82e6-560d989c5c8f',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        '89239ff0-a8ef-4e70-bfab-c7c49d55d23a': {
          id: '89239ff0-a8ef-4e70-bfab-c7c49d55d23a',
          data: {
            text: 'cmd + y - redo'
          },
          children: [],
          childIndex: 4,
          parent: '28a24c04-1823-4386-82e6-560d989c5c8f',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        '68823532-1233-46a1-8abc-63d80de3eb1b': {
          id: '68823532-1233-46a1-8abc-63d80de3eb1b',
          data: {
            text: 'to delete a bullet, delete its content'
          },
          children: [],
          childIndex: 6,
          parent: '28a24c04-1823-4386-82e6-560d989c5c8f',
          type: 'textarea',
          showChildren: true,
          complete: false
        }
      }
    }

export const resumeState: {nodes: INodes}= {
      nodes: {
        ROOT: {
          id: 'ROOT',
          data: {
            text: 'ListFold'
          },
          parent: 'ROOTX',
          children: [
            'd13271ba-649d-4f8a-b1c5-6a4a6b7d908d',
            '3240aeef-ca93-493b-b3a6-e36ba143b24f',
            '8bb0cac1-449e-4538-9f97-c076ef635f7b',
            'f15ce44f-9c4e-49fe-91fb-e8f9d07aedf0'
          ],
          childIndex: 0,
          type: 'textarea',
          complete: false,
          showChildren: true
        },
        'd13271ba-649d-4f8a-b1c5-6a4a6b7d908d': {
          id: 'd13271ba-649d-4f8a-b1c5-6a4a6b7d908d',
          data: {
            text: 'Iain Maitland'
          },
          children: [
            '7c72a8fd-1cc9-4f36-a0f1-375e2600ccde',
            'b3cc3e3f-97b1-4139-9c90-d628dccec09f',
            'b1ab3bb5-f1da-4fde-af8c-647f1a30d3d1'
          ],
          childIndex: 0,
          parent: 'ROOT',
          type: 'textarea',
          showChildren: false,
          complete: false
        },
        '7c72a8fd-1cc9-4f36-a0f1-375e2600ccde': {
          id: '7c72a8fd-1cc9-4f36-a0f1-375e2600ccde',
          data: {
            text: '737-226-3943'
          },
          children: [],
          childIndex: 0,
          parent: 'd13271ba-649d-4f8a-b1c5-6a4a6b7d908d',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        'b3cc3e3f-97b1-4139-9c90-d628dccec09f': {
          id: 'b3cc3e3f-97b1-4139-9c90-d628dccec09f',
          data: {
            text: 'iain@iainmaitland.com'
          },
          children: [],
          childIndex: 1,
          parent: 'd13271ba-649d-4f8a-b1c5-6a4a6b7d908d',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        'b1ab3bb5-f1da-4fde-af8c-647f1a30d3d1': {
          id: 'b1ab3bb5-f1da-4fde-af8c-647f1a30d3d1',
          data: {
            text: 'https://github.com/gl2748'
          },
          children: [],
          childIndex: 2,
          parent: 'd13271ba-649d-4f8a-b1c5-6a4a6b7d908d',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        '3240aeef-ca93-493b-b3a6-e36ba143b24f': {
          id: '3240aeef-ca93-493b-b3a6-e36ba143b24f',
          data: {
            text: 'Skills'
          },
          children: [
            '0094147b-5018-4a36-98eb-4f0e32d4151d',
            '9ef19952-dcbd-469d-85a2-b3caf79fe734',
            '768d8520-e93f-45a6-ae99-9dff2e335bde'
          ],
          childIndex: 1,
          parent: 'ROOT',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        '0094147b-5018-4a36-98eb-4f0e32d4151d': {
          id: '0094147b-5018-4a36-98eb-4f0e32d4151d',
          data: {
            text: 'React / React Native'
          },
          children: [],
          childIndex: 0,
          parent: '3240aeef-ca93-493b-b3a6-e36ba143b24f',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        '9ef19952-dcbd-469d-85a2-b3caf79fe734': {
          id: '9ef19952-dcbd-469d-85a2-b3caf79fe734',
          data: {
            text: 'Javascript / Typescript'
          },
          children: [],
          childIndex: 1,
          parent: '3240aeef-ca93-493b-b3a6-e36ba143b24f',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        '768d8520-e93f-45a6-ae99-9dff2e335bde': {
          id: '768d8520-e93f-45a6-ae99-9dff2e335bde',
          data: {
            text: 'DevOps fundamentals / AWS / GCS / Dockerization'
          },
          children: [],
          childIndex: 2,
          parent: '3240aeef-ca93-493b-b3a6-e36ba143b24f',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        '8bb0cac1-449e-4538-9f97-c076ef635f7b': {
          id: '8bb0cac1-449e-4538-9f97-c076ef635f7b',
          data: {
            text: 'Recent Work (2019)'
          },
          children: [
            '378b7733-9951-47e9-a7cf-f2e24811691c',
            'bc296d00-811c-4605-ba6a-091a9b219a16',
            '47130ab8-84f6-4648-a8f7-d7cc88933900',
            'fd25b1b4-d9e1-43d5-be6a-1ca108f3eaf5'
          ],
          childIndex: 2,
          parent: 'ROOT',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        '378b7733-9951-47e9-a7cf-f2e24811691c': {
          id: '378b7733-9951-47e9-a7cf-f2e24811691c',
          data: {
            text: 'hobbes.work'
          },
          children: [
            '70823e4a-37f4-4d62-9298-d790e56cec8a',
            '96a1fb20-5285-4dd9-8542-5cfec28db497',
            '1c60dce9-a0e2-437a-9db2-3d99e39c8c05'
          ],
          childIndex: 0,
          parent: '8bb0cac1-449e-4538-9f97-c076ef635f7b',
          type: 'textarea',
          showChildren: false,
          complete: false
        },
        '70823e4a-37f4-4d62-9298-d790e56cec8a': {
          id: '70823e4a-37f4-4d62-9298-d790e56cec8a',
          data: {
            text: 'Portfolio website for a Detroit based design company.'
          },
          children: [],
          childIndex: 0,
          parent: '378b7733-9951-47e9-a7cf-f2e24811691c',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        '96a1fb20-5285-4dd9-8542-5cfec28db497': {
          id: '96a1fb20-5285-4dd9-8542-5cfec28db497',
          data: {
            text: 'Lead team of two graphic designers to implement \na portfolio website with a CMS backend, user authentication and misc features.'
          },
          children: [],
          childIndex: 1,
          parent: '378b7733-9951-47e9-a7cf-f2e24811691c',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        '1c60dce9-a0e2-437a-9db2-3d99e39c8c05': {
          id: '1c60dce9-a0e2-437a-9db2-3d99e39c8c05',
          data: {
            text: 'React / Typescript / NodeJS / GatsbyJS'
          },
          children: [],
          childIndex: 2,
          parent: '378b7733-9951-47e9-a7cf-f2e24811691c',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        'bc296d00-811c-4605-ba6a-091a9b219a16': {
          id: 'bc296d00-811c-4605-ba6a-091a9b219a16',
          data: {
            text: 'beta.steemit.com'
          },
          children: [
            '91bb5f16-e065-4205-94f7-bb5ea7418b96',
            '875c4caa-64ef-4523-b331-0b6a6ba9b60d',
            '773ff047-2cfe-414f-ac8a-1a0254e73a7d',
            '3c15900d-c27f-4eb0-af6d-9666fe08a1fc'
          ],
          childIndex: 1,
          parent: '8bb0cac1-449e-4538-9f97-c076ef635f7b',
          type: 'textarea',
          showChildren: false,
          complete: false
        },
        '91bb5f16-e065-4205-94f7-bb5ea7418b96': {
          id: '91bb5f16-e065-4205-94f7-bb5ea7418b96',
          data: {
            text: 'Build out a rich set of app features on the Steem Blockchain.'
          },
          children: [],
          childIndex: 0,
          parent: 'bc296d00-811c-4605-ba6a-091a9b219a16',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        '875c4caa-64ef-4523-b331-0b6a6ba9b60d': {
          id: '875c4caa-64ef-4523-b331-0b6a6ba9b60d',
          data: {
            text: 'Community creation, moderation tools, voting, commenting.'
          },
          children: [],
          childIndex: 1,
          parent: 'bc296d00-811c-4605-ba6a-091a9b219a16',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        '773ff047-2cfe-414f-ac8a-1a0254e73a7d': {
          id: '773ff047-2cfe-414f-ac8a-1a0254e73a7d',
          data: {
            text: 'Manage the work of other developers, leading architecture decisions,\nassigning tasks, reviewing PRs.'
          },
          children: [],
          childIndex: 2,
          parent: 'bc296d00-811c-4605-ba6a-091a9b219a16',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        '3c15900d-c27f-4eb0-af6d-9666fe08a1fc': {
          id: '3c15900d-c27f-4eb0-af6d-9666fe08a1fc',
          data: {
            text: 'NodeJS / React / Redux / AWS'
          },
          children: [],
          childIndex: 3,
          parent: 'bc296d00-811c-4605-ba6a-091a9b219a16',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        '47130ab8-84f6-4648-a8f7-d7cc88933900': {
          id: '47130ab8-84f6-4648-a8f7-d7cc88933900',
          data: {
            text: 'listfold.com'
          },
          children: [
            '7ccc1ff7-5a99-4590-b0de-d9a412a97ba7',
            '7c27ff33-6a87-4211-a0d7-cc14808f7e96'
          ],
          childIndex: 2,
          parent: '8bb0cac1-449e-4538-9f97-c076ef635f7b',
          type: 'textarea',
          showChildren: false,
          complete: false
        },
        '7ccc1ff7-5a99-4590-b0de-d9a412a97ba7': {
          id: '7ccc1ff7-5a99-4590-b0de-d9a412a97ba7',
          data: {
            text: 'This site - a personal exploration of front-end web technologies\nlike typescript, state machines and progressive web apps'
          },
          children: [],
          childIndex: 0,
          parent: '47130ab8-84f6-4648-a8f7-d7cc88933900',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        '7c27ff33-6a87-4211-a0d7-cc14808f7e96': {
          id: '7c27ff33-6a87-4211-a0d7-cc14808f7e96',
          data: {
            text: 'React / Typescript / XState / AWS'
          },
          children: [],
          childIndex: 1,
          parent: '47130ab8-84f6-4648-a8f7-d7cc88933900',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        'fd25b1b4-d9e1-43d5-be6a-1ca108f3eaf5': {
          id: 'fd25b1b4-d9e1-43d5-be6a-1ca108f3eaf5',
          data: {
            text: 'http://keyternal.s3-website.us-east-2.amazonaws.com'
          },
          children: [
            '8311fa2f-c455-4556-a40f-18b73f7bd8e6',
            'ac66a53d-1544-4a87-925a-17d2970df4bf'
          ],
          childIndex: 3,
          parent: '8bb0cac1-449e-4538-9f97-c076ef635f7b',
          type: 'textarea',
          showChildren: false,
          complete: false
        },
        '8311fa2f-c455-4556-a40f-18b73f7bd8e6': {
          id: '8311fa2f-c455-4556-a40f-18b73f7bd8e6',
          data: {
            text: 'Work in progress marketing site for keytern.al'
          },
          children: [],
          childIndex: 0,
          parent: 'fd25b1b4-d9e1-43d5-be6a-1ca108f3eaf5',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        'ac66a53d-1544-4a87-925a-17d2970df4bf': {
          id: 'ac66a53d-1544-4a87-925a-17d2970df4bf',
          data: {
            text: 'React / WebGL / ThreeJS'
          },
          children: [],
          childIndex: 1,
          parent: 'fd25b1b4-d9e1-43d5-be6a-1ca108f3eaf5',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        'f15ce44f-9c4e-49fe-91fb-e8f9d07aedf0': {
          id: 'f15ce44f-9c4e-49fe-91fb-e8f9d07aedf0',
          data: {
            text: 'Experience'
          },
          children: [
            '354c1988-1117-4973-a962-b504d7b4c012',
            '6a9c38e0-9270-4e5e-ae93-75cb145857df',
            '684fe232-e4fb-4076-b19c-592cdaa553d2',
            '272ae009-ee62-4688-ab47-7c2c387bdb9f'
          ],
          childIndex: 3,
          parent: 'ROOT',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        '354c1988-1117-4973-a962-b504d7b4c012': {
          id: '354c1988-1117-4973-a962-b504d7b4c012',
          data: {
            text: '2019 - Present | steem.com | Software Engineer'
          },
          children: [
            '6ad19a34-79d3-488d-a11c-11319b18b822',
            '37a6d1c1-cfab-43b2-97a8-ce273db6cadc',
            '5a0d411d-9220-49d0-b021-0c4befc7fb35'
          ],
          childIndex: 0,
          parent: 'f15ce44f-9c4e-49fe-91fb-e8f9d07aedf0',
          type: 'textarea',
          showChildren: false,
          complete: false
        },
        '6a9c38e0-9270-4e5e-ae93-75cb145857df': {
          id: '6a9c38e0-9270-4e5e-ae93-75cb145857df',
          data: {
            text: '2018-2019 | bluewatertechnologies.com | Software Engineer'
          },
          children: [
            'fb01e8fd-1f25-4623-864f-774344fd6bbc',
            '291d6453-9832-4b7c-b0a9-c031b8da390d'
          ],
          childIndex: 1,
          parent: 'f15ce44f-9c4e-49fe-91fb-e8f9d07aedf0',
          type: 'textarea',
          showChildren: false,
          complete: false
        },
        '684fe232-e4fb-4076-b19c-592cdaa553d2': {
          id: '684fe232-e4fb-4076-b19c-592cdaa553d2',
          data: {
            text: '2017-2018 | quikly.com | Front-end developer'
          },
          children: [],
          childIndex: 2,
          parent: 'f15ce44f-9c4e-49fe-91fb-e8f9d07aedf0',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        '272ae009-ee62-4688-ab47-7c2c387bdb9f': {
          id: '272ae009-ee62-4688-ab47-7c2c387bdb9f',
          data: {
            text: '2016 -2017 | pmc.com | Drupal developer XXYY'
          },
          children: [],
          childIndex: 3,
          parent: 'f15ce44f-9c4e-49fe-91fb-e8f9d07aedf0',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        '6ad19a34-79d3-488d-a11c-11319b18b822': {
          id: '6ad19a34-79d3-488d-a11c-11319b18b822',
          data: {
            text: 'beta.steemit.com'
          },
          children: [
            'd68ea948-a149-48ea-8b7d-2127e4c870f6',
            '2d4e4540-87ed-40a3-92f3-f033d168f9f5'
          ],
          childIndex: 0,
          parent: '354c1988-1117-4973-a962-b504d7b4c012',
          type: 'textarea',
          showChildren: false,
          complete: false
        },
        '37a6d1c1-cfab-43b2-97a8-ce273db6cadc': {
          id: '37a6d1c1-cfab-43b2-97a8-ce273db6cadc',
          data: {
            text: 'steemitwallet.com'
          },
          children: [
            '5f4f4956-1189-4665-a9a6-5c8d4ddb37d1',
            'ab3fec2c-2a14-4411-9b5a-e5d2f4d1ee8d'
          ],
          childIndex: 1,
          parent: '354c1988-1117-4973-a962-b504d7b4c012',
          type: 'textarea',
          showChildren: false,
          complete: false
        },
        '5a0d411d-9220-49d0-b021-0c4befc7fb35': {
          id: '5a0d411d-9220-49d0-b021-0c4befc7fb35',
          data: {
            text: 'signup.steemit.com'
          },
          children: [
            '5ddea47a-75bd-47d6-a8f1-1e98c256acfd',
            '10db726c-47d4-4ec9-9cc9-bbdbdbbad32d'
          ],
          childIndex: 2,
          parent: '354c1988-1117-4973-a962-b504d7b4c012',
          type: 'textarea',
          showChildren: false,
          complete: false
        },
        'd68ea948-a149-48ea-8b7d-2127e4c870f6': {
          id: 'd68ea948-a149-48ea-8b7d-2127e4c870f6',
          data: {
            text: 'https://github.com/steemit/condenser/tree/bridge-api-dev'
          },
          children: [],
          childIndex: 0,
          parent: '6ad19a34-79d3-488d-a11c-11319b18b822',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        '5f4f4956-1189-4665-a9a6-5c8d4ddb37d1': {
          id: '5f4f4956-1189-4665-a9a6-5c8d4ddb37d1',
          data: {
            text: 'Leading developer of a fully featured cryptocurrency wallet.'
          },
          children: [],
          childIndex: 0,
          parent: '37a6d1c1-cfab-43b2-97a8-ce273db6cadc',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        'ab3fec2c-2a14-4411-9b5a-e5d2f4d1ee8d': {
          id: 'ab3fec2c-2a14-4411-9b5a-e5d2f4d1ee8d',
          data: {
            text: '$20 Million + annual transactions securely handled.'
          },
          children: [],
          childIndex: 1,
          parent: '37a6d1c1-cfab-43b2-97a8-ce273db6cadc',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        '2d4e4540-87ed-40a3-92f3-f033d168f9f5': {
          id: '2d4e4540-87ed-40a3-92f3-f033d168f9f5',
          data: {
            text: 'Leading developer of the largest DApp on the steemit blockchain.'
          },
          children: [],
          childIndex: 1,
          parent: '6ad19a34-79d3-488d-a11c-11319b18b822',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        '5ddea47a-75bd-47d6-a8f1-1e98c256acfd': {
          id: '5ddea47a-75bd-47d6-a8f1-1e98c256acfd',
          data: {
            text: 'Leading developer of the flagship account creation App for the Steem blockchain.'
          },
          children: [],
          childIndex: 0,
          parent: '5a0d411d-9220-49d0-b021-0c4befc7fb35',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        '10db726c-47d4-4ec9-9cc9-bbdbdbbad32d': {
          id: '10db726c-47d4-4ec9-9cc9-bbdbdbbad32d',
          data: {
            text: 'Implement rugged signup flow with KYC, and an admin dashboard.'
          },
          children: [],
          childIndex: 1,
          parent: '5a0d411d-9220-49d0-b021-0c4befc7fb35',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        'fb01e8fd-1f25-4623-864f-774344fd6bbc': {
          id: 'fb01e8fd-1f25-4623-864f-774344fd6bbc',
          data: {
            text: 'Lead 3 person development team building apps for GM and BestBuy.'
          },
          children: [],
          childIndex: 0,
          parent: '6a9c38e0-9270-4e5e-ae93-75cb145857df',
          type: 'textarea',
          showChildren: true,
          complete: false
        },
        '291d6453-9832-4b7c-b0a9-c031b8da390d': {
          id: '291d6453-9832-4b7c-b0a9-c031b8da390d',
          data: {
            text: 'Use OpenAPI specs for our APIs and leverage that to auto-generate client libraries\nin Swift, Typescript and Java.'
          },
          children: [],
          childIndex: 1,
          parent: '6a9c38e0-9270-4e5e-ae93-75cb145857df',
          type: 'textarea',
          showChildren: true,
          complete: false
        }
      }
    }