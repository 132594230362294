import { PointerActions, IPointer, IPointerAction } from "./actions";
import { initialState } from "../nodes/reducer";

export const pointer = (
  state: IPointer = initialState.pointer,
  action: IPointerAction
): IPointer => {
  if (typeof action === "undefined") {
    return state;
  }
  switch (action.type) {
    case PointerActions.UPDATE_POINTER:
      return action.payload;
    default:
      return state;
  }
};
