import { EditorActions, IEditor, IEditorAction } from "./actions";
import { initialState } from "../nodes/reducer";

export const editor = (
  state: IEditor = initialState.editor,
  action: IEditorAction
): IEditor => {
  if (typeof action === "undefined") {
    return state;
  }
  if (typeof action.payload === "undefined") {
    return state;
  }
  switch (action.type) {
    case EditorActions.UPDATE_EDITOR:
      return action.payload;
    default:
      return state;
  }
};
