import { IInitialState, IInitialStateWithHistory, initialState } from "./redux/nodes/reducer";

export const loadState = (): IInitialStateWithHistory | undefined => {
  try {
    const serializedState = localStorage.getItem('listFoldAppState');
    if (serializedState === null) {
      return undefined
    }
    // If the node tree is empty, return the default pointer position.
    let rehydratedState: IInitialStateWithHistory = JSON.parse(serializedState);
    if (Object.keys(rehydratedState.history.present.nodes).length === 1) {
      return {...rehydratedState, pointer: initialState.pointer}
    }
    return rehydratedState
  }catch (error) {
    return undefined
  }
}

export const saveState = (state: IInitialStateWithHistory): void => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('listFoldAppState', serializedState);
  } catch (error) {
    // Ignore write errors.
  }
}