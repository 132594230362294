import React, { Dispatch } from "react";
import { connect, MapStateToProps, MapDispatchToProps } from "react-redux";
import { undo, redo } from "../redux/undoable/actions";
import {
  IInitialStateWithHistory,
} from "../redux/nodes/reducer";

export interface ICommandsDispatchProps {
  undo: () => void;
  redo: () => void;
}

export interface ICommandsStateProps {
  canUndo: boolean;
  canRedo: boolean;
}

const mapDispatchToProps: MapDispatchToProps<ICommandsDispatchProps, {}> = (
  dispatch: Dispatch<any>
): ICommandsDispatchProps => {
  return {
    undo: () => dispatch(undo()),
    redo: () => dispatch(redo())
  };
};

const mapStateToProps: MapStateToProps<
  ICommandsStateProps,
  {},
  IInitialStateWithHistory
> = (state: IInitialStateWithHistory) => {
  return {
    canUndo: state.history.past.length > 0,
    canRedo: state.history.future.length > 0
  };
};

const GlobalCommands: React.FunctionComponent<
  ICommandsDispatchProps & ICommandsStateProps
> = ({ undo, redo, canRedo, canUndo }) => {
  if (document != null) {
    const d = document;
    d.onkeydown = (e: KeyboardEvent) => {
      if (e.key === "z" && e.metaKey === true && canUndo) {
        e.preventDefault();
        undo()
      }
      if (e.key === "z" && e.ctrlKey=== true && canUndo) {
        e.preventDefault();
        undo()
      }
      if (e.key === "y" && e.metaKey === true && canRedo) {
        e.preventDefault();
        redo()
      }
      if (e.key === "y" && e.ctrlKey && canRedo) {
        e.preventDefault();
        redo()
      }
    };
  }

  return null
};

export default connect<ICommandsStateProps, ICommandsDispatchProps>(
  mapStateToProps as MapStateToProps<ICommandsStateProps, {}, {}>,
  mapDispatchToProps
)(GlobalCommands);
