import { SortActions, ISortAction, IFocusEntity } from "./actions";
import { initialState } from "../nodes/reducer";

export const focus = (
  state: IFocusEntity = initialState.pointer,
  action: ISortAction
): IFocusEntity => {
  if (typeof action === "undefined") {
    return state;
  }
  switch (action.type) {
    case SortActions.MAINTAIN_FOCUS:
      return action.payload;
    default:
      return state;
  }
};
