import { BreadcrumbActions, IBreadcrumbAction } from "./actions";
import { initialState } from "../nodes/reducer";
import { IBreadcrumbState } from "../../containers/Breadcrumb";

export const breadcrumb = (
  state: IBreadcrumbState = initialState.breadcrumb,
  action: IBreadcrumbAction
): IBreadcrumbState => {
  if (typeof action === "undefined") {
    return state;
  }
  if (typeof action.payload === "undefined") {
    return state;
  }
  switch (action.type) {
    case BreadcrumbActions.UPDATE_CURRENT_BREADCRUMB:
      return {
        currentNode: action.payload,
      };
    default:
      return state;
  }
};
